// src/App.js
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles/App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import AuthHandler from './auth/AuthHandler';
import ProtectedRoute from './auth/ProtectedRoute';
import { AuthProvider } from './auth/AuthContext';

// Lazy loaded components
const Description = lazy(() => import('./components/Description'));
const Features = lazy(() => import('./components/Features'));
const Walkthrough = lazy(() => import('./components/Walkthrough'));
const Goals = lazy(() => import('./components/Goals'));
const Login = lazy(() => import('./components/Login'));
const Signup = lazy(() => import('./components/Signup'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const Profile = lazy(() => import('./components/Profile')); // Note the lowercase
const NotFound = lazy(() => import('./components/NotFound'));

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error:', error);
    console.error('Error Info:', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-container">
          <h2>Something went wrong.</h2>
          <button onClick={() => window.location.reload()}>
            Refresh Page
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Navbar />
        <div className="container">
          <ErrorBoundary>
            <Suspense fallback={<div className="loading-spinner">Loading...</div>}>
              <Routes>
                {/* Public routes */}
                <Route path="/" element={<Description />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/features" element={<Features />} />
                <Route path="/walkthrough" element={<Walkthrough />} />
                <Route path="/goals" element={<Goals />} />
                <Route path="/auth/handler" element={<AuthHandler />} />

                {/* Protected routes */}
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute>
                      <Profile />
                    </ProtectedRoute>
                  }
                />

                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </div>
        <Footer />
      </AuthProvider>
    </Router>
  );
};

export default App;