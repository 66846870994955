// src/auth/AuthContext.js
import { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext(null);
const API_URL = 'http://localhost:8000';

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserInfo = async (token) => {
    try {
      console.log('Fetching user info with token:', token); // Debug log

      const response = await fetch(`${API_URL}/auth/user`, {
        headers: {
          'Authorization': `Bearer ${token.replace('Bearer ', '')}`
        },
        credentials: 'include'
      });

      console.log('Response status:', response.status); // Debug log

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response:', errorData); // Debug log
        throw new Error(errorData.detail || 'Failed to fetch user info');
      }

      const userData = await response.json();
      console.log('User data received:', userData); // Debug log
      setUser(userData);
      return userData;
    } catch (error) {
      console.error('Fetch user info error:', error);
      localStorage.removeItem('auth_token');
      setUser(null);
      throw error;
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('auth_token');
      console.log('Stored token:', token); // Debug log
      
      if (token) {
        try {
          await fetchUserInfo(token);
        } catch (error) {
          console.error('Auth check failed:', error);
        }
      }
      setLoading(false);
    };

    checkAuth();
  }, []);

  const traditionalLogin = async (email, password) => {
    try {
      const response = await fetch(`${API_URL}/auth/login/traditional`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          'username': email,
          'password': password,
        }),
        credentials: 'include'
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.detail || 'Login failed');
      }

      const data = await response.json();
      const token = data.access_token;
      localStorage.setItem('auth_token', token);
      await fetchUserInfo(token);
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const register = async (username, email, password) => {
    try {
      const response = await fetch(`${API_URL}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          email,
          password,
          picture: ''
        }),
        credentials: 'include'
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.detail || 'Registration failed');
      }

      const data = await response.json();
      const token = data.access_token;
      localStorage.setItem('auth_token', token);
      await fetchUserInfo(token);
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  };

  const googleLogin = () => {
    window.location.href = `${API_URL}/auth/login`;
  };

  const logout = () => {
    localStorage.removeItem('auth_token');
    setUser(null);
  };


  return (
    <AuthContext.Provider value={{ 
      user, 
      loading, 
      traditionalLogin, 
      googleLogin, 
      register,
      logout,
      fetchUserInfo,
      isAuthenticated: !!user 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};