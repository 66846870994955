// src/components/Navbar.jsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import '../styles/Navbar.css';

const Navbar = () => {
    const { user } = useAuth();
    const location = useLocation();

    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    return (
        <nav className="navbar">
            <h1>
                <Link to="/" className="navbar-brand">
                    BoxedIn
                </Link>
            </h1>
            <div className="nav-links">
                {/* Public links visible to all */}
                <Link to="/features" className={isActive('/features')}>
                    Features
                </Link>
                <Link to="/goals" className={isActive('/goals')}>
                    Goals
                </Link>
                <Link to="/walkthrough" className={isActive('/walkthrough')}>
                    Walkthrough
                </Link>

                {user ? (
                    <>
                        <Link to="/dashboard" className={isActive('/dashboard')}>
                            Dashboard
                        </Link>
                        <Link to="/profile" className={isActive('/profile')}>
                            My Profile
                        </Link>
                    </>
                ) : (
                    <>
                        <Link to="/login" className={isActive('/login')}>
                            Login
                        </Link>
                        <Link to="/signup" className={isActive('/signup')}>
                            Sign Up
                        </Link>
                    </>
                )}
            </div>
        </nav>
    );
};

export default Navbar;