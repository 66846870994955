// src/components/AuthHandler.jsx
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';

const AuthHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchUserInfo } = useAuth();
  const [error, setError] = useState('');

  useEffect(() => {
    const handleAuth = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');

      console.log('Received token in handler:', token); // Debug log

      if (!token) {
        console.error('No token found in URL'); // Debug log
        setError('No authentication token found');
        setTimeout(() => navigate('/login'), 2000);
        return;
      }

      try {
        // Store the raw token
        localStorage.setItem('auth_token', token);
        
        // Wait a bit for token to be stored
        await new Promise(resolve => setTimeout(resolve, 100));
        
        await fetchUserInfo(token);
        navigate('/dashboard');
      } catch (error) {
        console.error('Auth handler error:', error);
        setError('Authentication failed');
        localStorage.removeItem('auth_token');
        setTimeout(() => navigate('/login'), 2000);
      }
    };

    handleAuth();
  }, [location, navigate, fetchUserInfo]);

  if (error) {
    return (
      <div className="auth-handler-container">
        <div className="error-message">{error}</div>
        <div className="redirect-message">Redirecting to login...</div>
      </div>
    );
  }

  return (
    <div className="auth-handler-container">
      <div className="loading">Authenticating...</div>
    </div>
  );
};

export default AuthHandler;